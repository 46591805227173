<app-header></app-header>
<div class="checkout-container" [ngClass]="themeService.currentPalette">
    <div  *ngIf="isActive"   class="title-checkout"><h1>checkout</h1></div>
    <div  *ngIf="!isActive"   class="title-checkout"><h1>PORTAL EN MANTENIMIENTO</h1></div>
    <div class="checkout-detail">
    <div class="checkout-column left-column">
      <!-- Bloque de inicio de sesión y registro -->
      <!-- <div *ngIf="!auth.isLoggedIn()" class="login-register-block">
        <h2>¿Tiene cuenta?</h2>
        <div class="button-login">
        <button (click)="showLoginPopup()" class="btn-login">Iniciar Sesión</button>
        <button class="btn-register">Registrarse</button>
        </div>
      </div> -->
      
      <!-- Formulario de checkout -->
      <div *ngIf="isActive" class="checkout-form-block">
        
        <form [formGroup]="form" >
            <!-- Columna izquierda del formulario -->
            

            <div class="checkout-dialog">
            <h2  class="pago">Información de pago</h2>
          </div>
          <div class="form-group">
            <label for="street">Calle: </label>
            <input [formControl]="form.controls['street']" type="text" id="street" name="street" placeholder="Calle" required>
            <small
                *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched"
                class="form-error-msg"> Calle es requerido </small>
          </div>
          <div class="form-group">
            <label for="direccion">Ciudad:</label>
            <input [formControl]="form.controls['city']" type="text" id="city" name="city" placeholder="Ciudad" required>
            <small
                *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched"
                class="form-error-msg"> Ciudad es requerido </small>
          </div>
          <div class="form-group">
            <label for="direccion">Codigo Postal:</label>
            <input [formControl]="form.controls['postalCode']" type="text" id="postalCode" name="postalCode" placeholder="Codigo Postal" required>
            <small
                *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched"
                class="form-error-msg"> Codigo Postal es requerido </small>
          </div>

          <div class="form-group">
            <label for="direccion">Telefono:</label>
            <input [formControl]="form.controls['phone']" type="text" id="phone" name="phone" placeholder="phone" required>
            <small
                *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched"
                class="form-error-msg"> Codigo Postal es requerido </small>
          </div>
          
            <div class="form-group">
              
                <label for="tarjeta">Nombre de Tarjeta:</label>
                <input [formControl]="form.controls['nameHolderCreditCard']" type="text" id="tarjeta" name="cc-name" placeholder="Nombre de Tarjeta" required>
                <small
                    *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched"
                    class="form-error-msg"> Nombre de tarjeta es requerido </small>
              </div>
              <div class="form-group">
                <label for="numero-tarjeta">Número de Tarjeta:</label>
                <input
                  (input)="onCreditCardInput(); formatCreditCardNumber()"
                  (keypress)="onlyNumberKey($event)"
                  [formControl]="form.controls['numberCreditCard']"
                  type="text"
                  id="numero-tarjeta"
                  name="cc-number"
                  placeholder="Número de Tarjeta"
                  required
                />
                <small
                  *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched"
                  class="form-error-msg"
                >
                  Número es requerido
                </small>
                <small
                  *ngIf="form.controls['numberCreditCard'].hasError('invalidLength') && form.controls['numberCreditCard'].touched"
                  class="form-error-msg"
                >
                  Número de tarjeta debe tener exactamente 16 dígitos.
                </small>
                <small
                  *ngIf="form.controls['numberCreditCard'].hasError('invalidCardNumber') && form.controls['numberCreditCard'].touched"
                  class="form-error-msg"
                >
                  Número de tarjeta no es válido.
                </small>
              </div>
              
              
              <div class="datos-mac">
              <div class="form-group">
                <label for="mes-expiracion">Mes de Expiración:</label>
                <select [formControl]="form.controls['expMonthCreditCard']"  id="mes-expiracion" name="mes-expiracion" required>
                  <option value="" disabled selected>Seleccionar Mes </option>
                  <option value="01" id="mes-exp-0">Enero</option>
                  <option value="02" id="mes-exp-1">Febrero</option>
                  <option value="03" id="mes-exp-2">Marzo</option>
                  <option value="04" id="mes-exp-3">Abril</option>
                  <option value="05" id="mes-exp-4">Mayo</option>
                  <option value="06" id="mes-exp-5">Junio</option>
                  <option value="07" id="mes-exp-6">Julio</option>
                  <option value="08" id="mes-exp-7">Agosto</option>
                  <option value="09" id="mes-exp-8">Septiembre</option>
                  <option value="10" id="mes-exp-9">Octubre</option>
                  <option value="11" id="mes-exp-10">Noviembre</option>
                  <option value="12" id="mes-exp-11">Diciembre</option>
                </select>
                <small
                *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched"
                class="form-error-msg"> Mes es requerido </small>

              </div>
              <div class="form-group">
                <label for="ano-expiracion">Año de Expiración:</label>
                <select [formControl]="form.controls['expYearCreditCard']"  id="ano-expiracion" name="ano-expiracion" required>
                  <option value="" disabled selected>Seleccionar Año</option>
                  <option value="24" id="ano-exp-2">2024</option>
                  <option value="25" id="ano-exp-3">2025</option>
                  <option value="26" id="ano-exp-4">2026</option>
                  <option value="27" id="ano-exp-5">2027</option>
                  <option value="28" id="ano-exp-6">2028</option>
                  <option value="29" id="ano-exp-7">2029</option>
                  <option value="30" id="ano-exp-8">2030</option>
                  <option value="31" id="ano-exp-9">2031</option>
                  <option value="32" id="ano-exp-10">2032</option>
                  <option value="33" id="ano-exp-11">2033</option>
                  <option value="34" id="ano-exp-12">2034</option>
                  <option value="35" id="ano-exp-13">2035</option>
                  <option value="36" id="ano-exp-14">2036</option>
                  <option value="37" id="ano-exp-15">2037</option>
                  <option value="38" id="ano-exp-16">2038</option>
                  <option value="39" id="ano-exp-17">2039</option>
                  <option value="40" id="ano-exp-18">2040</option>
                </select>
                <small
                        *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched"
                        class="form-error-msg"> Año es requerido </small>
              </div>
              <div class="form-group">
                <label for="cvv">CVV:</label>
                <input maxlength="3"  (keypress)="onlyNumberKey($event)" (input)="onCVVInputChange()" [formControl]="form.controls['ccv']" type="password" id="cvv" name="cvv" placeholder="CVV" required>
                <small
                *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
                class="form-error-msg"> CVV es requerido </small>
              </div> </div>
            <button (click)="checkout()" class="btn-submit">Realizar Pago</button>
          
          </form>
      </div>
    </div>
  
  
<div *ngIf="isActive" class="checkout-summary">
    <h2>Resumen del Pedido</h2>
    <div class="product-item">
      <img src="{{plan?.url_image}}" alt="Producto 1">
      <div class="product-info">
        <h3>{{ plan?.name_plan }}</h3>
        <p>Descripcion: {{ plan?.description }} </p>
      </div>
    </div>
    <hr>
    <!-- Total -->
    <div class="order-total">
      <p>Total: ${{ plan?.feeCost_plan }}</p>
    </div>
  </div>
  
</div>
  </div>
  